import '@hotwired/turbo'

import translation from '@onpace/onspace-core/components/translations'
import OnspaceDialog from '@onpace/onspace-core/elements/dialog'

////////// Visits

let turboBeforeVisitCallback = null
let turboRenderCallback = null

document.addEventListener('turbo:click', (event) => {
  const action = event.target.getAttribute('data-turbo-action')
  switch (action) {
  case 'dialog':
    turboBeforeVisitCallback = (visitEvent) => {
      const dialog = new OnspaceDialog()
      dialog.loadUrl(event.target.href)
      document.body.appendChild(dialog)

      visitEvent.preventDefault()
    }
    return
  case 'tooltip':
    turboBeforeVisitCallback = (visitEvent) => {
      const dialog = new OnspaceDialog({ tooltip: true })
      dialog.loadUrl(event.target.href)
      document.body.appendChild(dialog)

      visitEvent.preventDefault()
    }
    return
  }

  if (document.onspaceHasChangedForm) {
    turboBeforeVisitCallback = (visitEvent) => {
      const result = window.confirm(translation('onspace.navigation.form_unsaved.link'))
      if (!result) {
        visitEvent.preventDefault()
      }
    }
  }
})

document.addEventListener('turbo:before-visit', (event) => {
  if (turboBeforeVisitCallback) {
    turboBeforeVisitCallback(event)
    turboBeforeVisitCallback = null
  }
})

document.addEventListener('turbo:before-prefetch', (event) => {
  event.preventDefault()
})

document.addEventListener('turbo:before-fetch-response', (event) => {
  const dialogHeader = event.detail.fetchResponse.response.headers.get('Turbo-Open-Dialog')
  if (dialogHeader) {
    turboRenderCallback = (_renderEvent) => {
      const dialog = new OnspaceDialog()
      dialog.loadUrl(dialogHeader)
      document.body.appendChild(dialog)
    }
  }
})

document.addEventListener('turbo:render', (event) => {
  if (turboRenderCallback) {
    turboRenderCallback(event)
    turboRenderCallback = null
  }
})

document.addEventListener('turbo:frame-render', (event) => {
  if (turboRenderCallback) {
    turboRenderCallback(event)
    turboRenderCallback = null
  }
})

window.addEventListener('beforeunload', (event) => {
  if (document.onspaceHasChangedForm) {
    event.preventDefault()
    event.returnValue = translation('onspace.navigation.form_unsaved.link')
    return event.returnValue
  }
})

////////// Auto-Reloading

document.addEventListener('turbo:frame-load', (event) => {
  const reloadInterval = event.target.getIntegerAttribute('data-reload-interval')
  if (reloadInterval && reloadInterval > 0) {
    setTimeout(() => event.target.reload(), reloadInterval * 1000)
  }
})

////////// Forms

document.addEventListener('turbo:submit-start', (event) => {
  const submission = event.detail.formSubmission
  const form = submission.formElement
  const button = submission.submitter

  submission.fetchRequest.headers['Turbo-Action'] = 'submit'

  disableForm(form, button)
})

document.addEventListener('onspace:media:upload-start', (event) => {
  const submission = event.detail.formSubmission
  const form = submission.formElement
  const button = submission.submitter

  disableForm(form, button)
})
document.addEventListener('onspace:media:upload-end', (event) => {
  const submission = event.detail.formSubmission
  const form = submission.formElement
  const button = submission.submitter

  enableForm(form, button)
})
document.addEventListener('onspace:media:upload-error', (event) => {
  const submission = event.detail.formSubmission
  const form = submission.formElement
  const button = submission.submitter

  enableForm(form, button)
})

function disableForm(form, button) {
  form.querySelectorAll('fieldset').forEach((element) => element.disabled = true)

  if (button) {
    const icon = SVGElement.createOnspaceSpritemapSvg('onspace/icon_loading')
    button.appendChild(icon)
    button.turboLoadingIcon = icon
  }
}

function enableForm(form, button) {
  form.querySelectorAll('fieldset').forEach((element) => element.disabled = false)

  if (button) {
    if (button.turboLoadingIcon) {
      button.turboLoadingIcon.remove()
      button.turboLoadingIcon = null
    }
  }
}

////////// JS Execution

document.addEventListener('turbo:before-fetch-response', async (event) => {
  if (event.detail.fetchResponse.contentType.match(/^text\/javascript(;|$)/)) {
    const downloadUrl = event.detail.fetchResponse.response.headers.get('Turbo-Download')
    if (downloadUrl) {
      await turboDownload(downloadUrl)
    }

    // Safari will crash if we try to make page changes when there are open dialogs
    const dialog = event.target.closest('onspace-dialog')
    if (dialog) {
      await dialog.close({ ignoreForms: true })
    }

    event.preventDefault()
    event.detail.fetchResponse.responseText
      .then((responseText) => {
        (new Function(responseText))()
      })
  }
})

async function turboDownload(url) {
  return new Promise((resolve, _reject) => {
    const anchor = document.createElement('a')
    anchor.href = url
    anchor.setAttribute('download', '')
    anchor.click()

    setTimeout(() => resolve(), 500)
  })
}
