import '@onpace/onspace-core/elements/form/conditional'
import '@onpace/onspace-core/elements/form/color_custom'
import '@onpace/onspace-core/elements/form/date_custom'
import '@onpace/onspace-core/elements/form/filter_field'
import '@onpace/onspace-core/elements/form/input_array'
import '@onpace/onspace-core/elements/form/rich_text'
import '@onpace/onspace-core/elements/form/select_custom'

document.addEventListener('turbo:load', (_event) => {
  document.querySelectorAll('.onspace-form [onspace-form-collapse], .onspace-form [onspace-form-collapsed]').forEach((collapse) => {
    const form = collapse.closest('form')
    form.classList.add('onspace-form--collapsed')

    collapse.addEventListener('click', (_event) => {
      form.classList.toggle('onspace-form--collapsed')
    })
  })

  document.querySelectorAll('.onspace-form textarea').forEach((textarea) => {
    adjustTextAreaHeight(textarea)
    textarea.addEventListener('input', (_event) => {
      adjustTextAreaHeight(textarea)
    })
  })
})

function adjustTextAreaHeight(textarea) {
  textarea.style.height = 'auto'
  textarea.style.height = `${textarea.scrollHeight}px`
}
